.formField {
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
}

.capitalized {
  text-transform: capitalize;
  font-weight: 400;
}

.list-cell {
  padding: 8
}

body {
  font-family: 'Open Sans', sans-serif;
}